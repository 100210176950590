import { createAction } from '@reduxjs/toolkit'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { updateSingleDocumentMapping } from '../effects/updateSingleDocumentMapping'
import { fetchDocumentChunksAndMappings } from './data-prep/prepareMarkupData'
import { duplicateDocumentChunk, duplicateDocumentChunkAndEstimations } from '../../../api/projects-api'
import { DocumentChunk } from '../../../models/documentChunk'
import { DocumentMapping } from '../../../models/documentMapping'
import { updateToolbarMessage } from '../../slices/common'
import { selectDocumentMappings } from '../../slices/documents'
import { selectProjectId } from '../../slices/project'
import { COPYING_PAGES } from '../../types'

type DuplicateAndMovePagesPayload = {
    chunkIds: number[]
    buildingId: number
    shouldCopyWithEstimations: boolean
}

export const duplicateAndMovePagesAction = createAction<DuplicateAndMovePagesPayload>('duplicateAndMovePages')

export function* handleDuplicateAndMovePages({ payload }: ReturnType<typeof duplicateAndMovePagesAction>) {
    yield put(updateToolbarMessage(COPYING_PAGES))
    const { chunkIds, buildingId, shouldCopyWithEstimations } = payload
    const mappings: DocumentMapping[] | null = yield select(selectDocumentMappings)
    const projectId: number | null = yield select(selectProjectId)

    if (!mappings || !projectId) return

    if (!chunkIds.every((id) => !!mappings.find((mapping) => mapping.document_chunk_id === id))) return

    try {
        const updatedDocumentMappingsResults = chunkIds.map((id) => {
            return shouldCopyWithEstimations
                ? call(duplicateDocumentChunkAndEstimations, projectId, id)
                : call(duplicateDocumentChunk, projectId, id)
        })

        const results: (DocumentChunk & { document_chunk_mappings: DocumentMapping[] })[] = yield all(
            updatedDocumentMappingsResults
        )

        for (const result of results) {
            const mapping = result.document_chunk_mappings[0]

            yield put(
                updateSingleDocumentMapping({
                    ...mapping,
                    is_estimation_complete: false,
                    building_id: buildingId,
                    additional_data: {
                        ...mapping.additional_data,
                        baseHomes: undefined,
                        options: undefined,
                        estimatedBaseHomeId: undefined,
                    },
                    overlay_center: [0, 0],
                    overlay_rotation: 0,
                    overlay_scale: 0,
                })
            )
        }

        yield call(fetchDocumentChunksAndMappings, projectId)
    } catch (e) {
        yield call(console.error, e)
    } finally {
        yield put(updateToolbarMessage(null))
    }
}

export function* watchForDuplicatingAndMovingPages() {
    yield takeEvery(duplicateAndMovePagesAction, handleDuplicateAndMovePages)
}
