import { put, select, takeEvery } from 'redux-saga/effects'

import { updateSingleDocumentMapping } from '../effects/updateSingleDocumentMapping'
import { UPDATE_DRAWABLES_MEASUREMENTS, UPDATE_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { DocumentMapping } from '../../../models/documentMapping'
import { selectCurrentEstimatedOptionOverlayChunkId } from '../../slices/2D'
import { selectOverlaidDocumentMapping } from '../../slices/documents'
import { updateConflictResolutionFlag } from '../../slices/masterSetPlan'

export function* updateEstimatedOverlayChunkCompletionStatus() {
    const currentEstimatedOptionOverlayChunkId: number | undefined = yield select(
        selectCurrentEstimatedOptionOverlayChunkId
    )

    const overlaidMapping: DocumentMapping | null = yield select(selectOverlaidDocumentMapping)

    if (!currentEstimatedOptionOverlayChunkId || !overlaidMapping) return

    if (overlaidMapping.is_option && overlaidMapping.is_estimation_complete) {
        yield put(updateSingleDocumentMapping({ ...overlaidMapping, is_estimation_complete: false }))
        yield put(updateConflictResolutionFlag(false))
    }
}

export function* watchForAnyMaterialChange() {
    yield takeEvery(
        [UPDATE_OPENING_GROUPS_SUCCESS, UPDATE_DRAWABLES_MEASUREMENTS],
        updateEstimatedOverlayChunkCompletionStatus
    )
}
