import { put, select, takeLatest } from 'redux-saga/effects'

import { SET_ACTIVE_FLOOR, setActiveFloor } from '../../../actions/drawable'
import { DocumentMapping } from '../../../models/documentMapping'
import { PROJECT_PAGE_TAB_VALUES } from '../../../shared/constants/project-settings-names'
import {
    clearSelectedAiSuggestionIds,
    selectCurrentOverlayChunkId,
    setEstimatedOptionOverlayChunkId,
    setOverlayChunkId,
} from '../../slices/2D'
import { selectDocumentMappings } from '../../slices/documents'
import {
    updateConflictResolutionFlag,
    updateOverlaidChunkLockedFlag,
    updateOverlaySwitchConfirmationFlag,
} from '../../slices/masterSetPlan'
import { setActiveTabOnProjectPage } from '../../slices/navigation'

export function* handleResetActiveOverlayOnPageChange(
    triggerAction: ReturnType<typeof setActiveTabOnProjectPage> | ReturnType<typeof setActiveFloor>
) {
    // Short circuit if changing tab to digitizer
    if (
        triggerAction.type === 'navigation/setActiveTabOnProjectPage' &&
        triggerAction.payload === PROJECT_PAGE_TAB_VALUES['DIGITIZER']
    ) {
        return
    }

    const overlayChunkId: number | null = yield select(selectCurrentOverlayChunkId)
    const documentMappings: DocumentMapping[] | null = yield select(selectDocumentMappings)

    if (!documentMappings) return

    const overlaidMapping: DocumentMapping | undefined = documentMappings.find(
        (mapping) => mapping.document_chunk_id === overlayChunkId
    )

    if (overlaidMapping && overlaidMapping.is_option && !overlaidMapping.is_estimation_complete) {
        yield put(updateConflictResolutionFlag(false))
        yield put(updateOverlaidChunkLockedFlag(false))
        yield put(updateOverlaySwitchConfirmationFlag(true))
    } else {
        yield put(setOverlayChunkId(null))
        yield put(setEstimatedOptionOverlayChunkId(null))

        yield put(clearSelectedAiSuggestionIds())
    }
}

export function* watchForPageChangeAndResetOverlay() {
    // Watches for change of plan page
    yield takeLatest(SET_ACTIVE_FLOOR, handleResetActiveOverlayOnPageChange)
    // Watches for tab change to Digitizer
    yield takeLatest(setActiveTabOnProjectPage, handleResetActiveOverlayOnPageChange)
}
